<template>
    <div class="theory-list">
        <template v-if="type === 2 || level === 2 || type === 5 || level === 5 || type === 6 || level === 6">
            <el-table class="customTable" :data="studentList"
                      style="width: 100%; margin-top: 20px; flex: 1;border: 1px solid #EEEEEE;" height="1%" size="medium"
                      :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                      :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                <el-table-column prop="module" align="left" label="考试内容"></el-table-column>
                <!-- <el-table-column prop="score" align="center" label="评分">
                    <template slot-scope="scope">
                        <span>{{scope.row.hasOwnProperty('score') ? scope.row.score : '未评分'}}</span>
                    </template>
                </el-table-column> -->
                <el-table-column prop="dz_score" align="center" label="店铺LOGO、店招" v-if="newDecorate.dz_score"></el-table-column>
                <el-table-column prop="poster_score" align="center" label="Banner" v-if="newDecorate.poster_score"></el-table-column>
                <el-table-column prop="goods_img_score" align="center" label="商品主图" v-if="newDecorate.goods_img_score"></el-table-column>
                <el-table-column prop="goods_details_score" align="center" label="商品详情页" v-if="newDecorate.goods_details_score"></el-table-column>
                <el-table-column prop="goods_short_video_score" align="center" label="商品短视频" v-if="newDecorate.goods_short_video_score"></el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <el-link type="primary" :underline="false"
                                 class="view-detail" @click="lookStoreDetail(scope.row)">查看详情
                        </el-link>
                    </template>
                </el-table-column>
            </el-table>
        </template>
        <template v-else>
            <el-table class="customTable" :data="studentList"
                      style="width: 100%; margin-top: 20px; flex: 1;border: 1px solid #EEEEEE;" height="1%" size="medium"
                      :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                      :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                <el-table-column prop="module" align="left" label="考试内容"></el-table-column>
                <el-table-column align="center" label="学生答案">
                    <template slot-scope="scope">
                        <div class="goods-wrapper" v-if="scope.row.url">
                            <div class="goods-cover">
                                <img :src="scope.row.module === '用户页面装修-店招' ? scope.row.url : scope.row.url[0]" alt="">
                            </div>
                        </div>
                        <div v-else>
                            未提交
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="score" align="center" label="评分">
                    <template slot-scope="scope">
                        <span>{{scope.row.hasOwnProperty('score') ? scope.row.score : '未评分'}}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <el-link v-if="scope.row.url" type="primary" :underline="false"
                                 class="view-detail" @click="lookDetail(scope.row)">查看详情
                        </el-link>
                        <span v-else>未提交</span>
                    </template>
                </el-table-column>
            </el-table>
        </template>

<!--        <el-pagination class="pager-center" style="margin: 20px;text-align: center"-->
<!--                       :current-page="listPages.currentPageNum"-->
<!--                       :page-size="listPages.eachPageNum"-->
<!--                       :total="listPages.total"-->
<!--                       layout="prev, pager, next, jumper"-->
<!--                       @current-change="listCurrentChange">-->
<!--        </el-pagination>-->
        <el-dialog title="查看详情" :visible.sync="detailDialog" width="830px" custom-class="green-dialog">
            <div v-show="imgType === 'dz'">
                <img :src="imgSrc" alt="" width="100%">
            </div>

            <div v-show="imgType === 'banner'">
                <div v-swiper:mySwiper="bannerOption" class="computer-banner">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" :key="bannerItem.id" v-for="bannerItem in imgList">
                            <img :src="bannerItem ? bannerItem : require('../../../assets/images/noimg_banner.png')" class="banner-img">
                        </div>
                        <div class="swiper-slide" v-if="imgList.length === 0">
                            <div class="no-img">
                                <img :src="require('../../../assets/images/noimg_banner.png')" class="banner-img">
                                <span style="color: #999999;margin-top: 10px;">还未上传海报,点击上传</span>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-pagination" slot="pagination"></div>
                    <div class="banner-swiper-btn-prev">
                        <i class="iconfont">&#xe680;</i>
                    </div>
                    <div class="banner-swiper-btn-next">
                        <i class="iconfont">&#xe602;</i>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'

    import {teacheropDzPosterData, teacheropShopDecorationData} from '@/utils/apis'

    export default {
        name: "Index",
        directives: {
            swiper: directive
        },
        data(){
            return {
                role:Number(localStorage.getItem('role')) || '',
                studentList:[],
                // listPages:{
                //     currentPageNum: 1,
                //     eachPageNum: 10,
                //     total: 0,
                // },
                examId:this.$route.query.examId || '',
                user_id:this.$route.query.id || '',
                detailDialog: false,
                imgType: '',
                imgSrc: '',
                imgList: [],
                bannerOption: {
                    autoplay: {
                        delay: 3000,
                        disableOnInteraction: false
                    },
                    initialSlide: 0,
                    autoHeight: true,
                    loop: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    navigation: {
                        nextEl: '.banner-swiper-btn-next',
                        prevEl: '.banner-swiper-btn-prev',
                    },
                    observer: true,
                    observeParents:true,
                },
                type: Number(this.$route.query.type) || null,
                level: Number(this.$route.query.level) || null,
                newDecorate: {},
            }
        },
        mounted() {
            if (this.type === 2 || this.level === 2 || this.type === 5 || this.level === 5 || this.type === 6 || this.level === 6) {
                this.getList()
            } else {
                this.getStudentList();
            }
        },
        methods:{
            getList() {
                let params = {
                    exam_id: this.examId,
                    user_id: this.user_id,
                }
                teacheropShopDecorationData(params).then((res)=>{
                    let params = {
                        module: '线上店铺设计与装修',
                        dz_score: res.data.dz_score,
                        goods_details_score: res.data.goods_details_score,
                        goods_img_score: res.data.goods_img_score,
                        goods_short_video_score: res.data.goods_short_video_score,
                        poster_score: res.data.poster_score,
                    }
                    this.newDecorate = res.data;
                    this.studentList.push(params)
                }).catch((err)=>{
                    console.log('err',err)
                })
            },
            getStudentList(){
                let params = {
                    exam_id: this.examId,
                    user_id: this.user_id,
                }
                teacheropDzPosterData(params).then(res => {
                    this.studentList = res.data
                }).catch(err => {})
            },
            // 查看详情
            lookDetail(row){
                if (row.module === '用户页面装修-店招') {
                    this.imgType = 'dz'
                    this.imgSrc = row.url
                } else {
                    this.imgType = 'banner'
                    this.imgList = row.url
                }
                this.detailDialog = true
            },
            lookStoreDetail(row) {
                let routeData
                if (this.role === 6) {
                    routeData = this.$router.resolve({
                        // path: '/stuStore1',
                        path:'/newStuStore1',
                        query: {
                            exam_id: this.examId,
                            user_id: this.$route.query.id
                        }
                    })
                } else {
                    routeData = this.$router.resolve({
                        path: '/stuStore',
                        query: {
                            exam_id: this.examId,
                            user_id: this.$route.query.id
                        }
                    })
                }

                window.open(routeData.href, "_blank");
            },
            // listCurrentChange(val){
            //     this.listPages.currentPageNum = val;
            //     this.getStudentList();
            // },
        }
    }
</script>

<style scoped lang="scss">
    .theory-list{
        padding: 0px 20px;
        box-sizing: border-box;
        height: 100%;
        display: flex;
        flex-direction: column;
        .theory-list-header{
            display: flex;
            align-items: center;
            span{
                line-height: 1;
            }
        }
    }
    .goods-wrapper {
        display: inline-flex;
        align-items: center;
        .goods-cover {
            width: 100px;
            height: 40px;
            display: flex;
            background: #F6F6F6;
            img  {
                max-width: 100%;
                max-height: 100%;
                margin: auto;
            }
        }
        .goods-name {
            width: 1%;
            flex: 1;
            margin-left: 10px;
            font-size: 16px;
        }
    }

    .computer-banner {
        width: 100%;
        /*height: 436px;*/
        height: 395px;
        .banner-img {
            display: block;
            /*width: calc(100% - 180px);*/
            /*max-height: 630px;*/
            max-width: 100%;
            max-height: 100%;
            margin: 29px auto 0;
        }
        ::v-deep .swiper-wrapper {
            height: calc(100% - 20px) !important;
            .swiper-slide {
                display: flex;
                justify-content: center;
                align-items: center;
                height: calc(100% - 20px) !important;
            }
        }
        ::v-deep .swiper-pagination {
            position: relative;
            bottom: 0;
        }
        ::v-deep .swiper-pagination-bullets {
            .swiper-pagination-bullet {
                width: 30px;
                height: 3px;
                background: #2C405F;
                border-radius: 0;
            }
        }
        .no-img {
            width: 264px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .banner-swiper-btn-prev,
        .banner-swiper-btn-next {
            width: 90px;
            position: absolute;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 10;
            opacity: .5;
            cursor: pointer;
            img {
                display: block;
                width: 20px;
            }
            &:hover {
                opacity: 1;
            }
        }
        .banner-swiper-btn-prev {
            left: 0;
            i {
                color: #BFBFBF;
                font-size: 30px;
            }
        }
        .banner-swiper-btn-next {
            right: 0;
            i {
                color: #BFBFBF;
                font-size: 34px;
            }
        }
    }
</style>