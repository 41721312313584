<template>
  <div class="answer-content">
    <div class="main-wrapper-content">
      <PageSet :detail-data="detailData" v-if="Object.keys(detailData).length > 0 && detailData.module_id === 12" />
      <PageSet v-if="type === 2 || level === 2 || type === 5 || level === 5 || type === 6 || level === 6" />
      <StoreSetPageDetail :detail-data="detailData" v-if="Object.keys(detailData).length > 0 && detailData.module_id === 11" />
<!--      <CustomPageDetail :detail-data="detailData" v-if="Object.keys(detailData).length > 0 && detailData.module_id !== 11" />-->
    </div>
  </div>
</template>

<script>
import {sactionExamDetail} from '@/utils/apis'
import CustomPageDetail from '@/components/student/train/CustomPageDetail.vue'
import StoreSetPageDetail from '@/components/student/train/StoreSetPageDetail.vue'
import PageSet from '@/components/student/train/PageSet.vue'
export default {
  name: "StoreDecorate",
  components:{
    CustomPageDetail,
    StoreSetPageDetail,
    PageSet
  },
  data(){
    return {
      stuId:this.$route.query.id || null,
      examId:this.$route.query.examId || null,
      detailData:{},

      type: Number(this.$route.query.type) || null,
      level: Number(this.$route.query.level) || null,
    }
  },
  mounted() {
    if (this.type === 1 || this.level === 1) {
      this.getSactionExamDetail()
    }
  },
  methods:{
    getSactionExamDetail(){
      let params = {
        exam_id:this.examId,
        student_id:this.stuId
      }
      sactionExamDetail(params).then((res)=>{
          console.log('res', res);
        this.detailData = res.data;
      }).catch((err)=>{
        console.log('err',err)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.answer-content{
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 20px;
  .main-wrapper-content{
    flex: 1;
    height: 1%;
    display: flex;
    flex-direction: column;
  }
}
</style>